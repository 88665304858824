import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import CustomDrawer from '../../components/UI/CustomComponents/CustomDrawer';
import { DrawerMenu } from '../Lists/DashboardCardLists/menu-list';
import { filterListByUserPermissions } from '../functions/filterListByUser';
import { User } from '../../store/user/user';

const useDrawer = (Component: React.ComponentType, menuList: DrawerMenu[], user: User) => (props: any) => {
  const [isExpanded, setIsExpanded] = useState(() => {
    const savedState = localStorage.getItem('drawerExpanded');
    return savedState ? JSON.parse(savedState) : false;
  });

  useEffect(() => {
    localStorage.setItem('drawerExpanded', JSON.stringify(isExpanded));
  }, [isExpanded]);

  const drawerWidth = 200;
  const collapsedDrawerWidth = 65;
  const authorizedMenuList = filterListByUserPermissions(menuList, user);

  return (
    <>
      <CustomDrawer
        menuList={authorizedMenuList}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        drawerWidth={drawerWidth}
        collapsedDrawerWidth={collapsedDrawerWidth}
      />
      <Box
        sx={{
          marginLeft: `${isExpanded ? drawerWidth : collapsedDrawerWidth}px`,
          width: `calc(100vw - ${isExpanded ? drawerWidth : collapsedDrawerWidth}px)`,
          padding: '1rem',
          transition: 'margin-left 0.7s ease-out, width 0.7s ease-out',
        }}
      >
        <Component
          {...props}
          authorizedMenuList={authorizedMenuList}
          isExpanded={isExpanded}
          drawerWidth={drawerWidth}
          collapsedDrawerWidth={collapsedDrawerWidth}
        />
      </Box>
    </>
  );
};

export default useDrawer;
