import { createSlice } from '@reduxjs/toolkit';
import { CLEAN_ALL_STATES } from '../global-actions';
import { ProductionOrderState } from './production-order';
import {
  CREATE_PRODUCTION_ORDER, 
  FETCH_ALL_PRODUCTION_ORDERS, UPDATE_PRODUCTION_ORDER,
  UPLOAD_PRODUCTION_ORDER_FILE, 
  FETCH_ALL_OPERATIONS_IN_PRODUCTION_ORDERS,
  GET_ONE_PRODUCTION_ORDER_BY_CODE,
  FLASH_PRODUCTION_ORDER_OPERATION_STOP,
} from './production-order.thunk';

const initialState: ProductionOrderState = {
  list: [],
  operationList: [],
  isLoading: false,
};

export const productionOrderSlice = createSlice({
  name: 'productionOrderSlice',
  initialState,
  reducers: {
    cleanProductionOrders(state) {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_ALL_PRODUCTION_ORDERS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_ALL_PRODUCTION_ORDERS.fulfilled, (state, action) => ({
      ...state, list: action.payload, isLoading: false,
    }));
    builder.addCase(FETCH_ALL_PRODUCTION_ORDERS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(GET_ONE_PRODUCTION_ORDER_BY_CODE.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FLASH_PRODUCTION_ORDER_OPERATION_STOP.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FLASH_PRODUCTION_ORDER_OPERATION_STOP.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(FLASH_PRODUCTION_ORDER_OPERATION_STOP.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(GET_ONE_PRODUCTION_ORDER_BY_CODE.fulfilled, (state, action) => ({
      ...state, list: [action.payload], isLoading: false,
    }));
    builder.addCase(GET_ONE_PRODUCTION_ORDER_BY_CODE.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(FETCH_ALL_OPERATIONS_IN_PRODUCTION_ORDERS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_ALL_OPERATIONS_IN_PRODUCTION_ORDERS.fulfilled, (state, action) => ({
      ...state, operationList: action.payload, isLoading: false,
    }));
    builder.addCase(FETCH_ALL_OPERATIONS_IN_PRODUCTION_ORDERS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CREATE_PRODUCTION_ORDER.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_PRODUCTION_ORDER.fulfilled, (state, action) => {
      state.list.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(CREATE_PRODUCTION_ORDER.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(UPDATE_PRODUCTION_ORDER.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(UPDATE_PRODUCTION_ORDER.fulfilled, (state, action) => {
      state.list = state.list.map((productionOrderItem) => (productionOrderItem._id === action.payload._id
        ? action.payload : productionOrderItem));
      state.isLoading = false;
    });
    builder.addCase(UPDATE_PRODUCTION_ORDER.rejected, (state) => {
      state.isLoading = false;
    }); 
    builder.addCase(UPLOAD_PRODUCTION_ORDER_FILE.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(UPLOAD_PRODUCTION_ORDER_FILE.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(UPLOAD_PRODUCTION_ORDER_FILE.rejected, (state) => {
      state.isLoading = false;
    }); 
    
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const ProductionOrderActions = {
  ...productionOrderSlice.actions,
};
