import { createAsyncThunk } from '@reduxjs/toolkit';
import { UiSlice } from '../ui/ui.slice';
import axiosApiInstance from '../../config/axios';
import { GetTasksDto } from './task';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI || '/api';

export const CREATE_TASK = createAsyncThunk(
  'task/CREATE_TASK',
  async (payload: any, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/tasks/create`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Nouvelle rubrique enregistrée',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: err.message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

export const GET_TASKS = createAsyncThunk(
  'task/GET_TASKS',
  async (payload: GetTasksDto, thunkApi) => {
    try {
      const result = await axiosApiInstance.post(`${url}/tasks/getTasks`, payload);
      return result.data;
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: err.message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);
