import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff, ChevronRight } from '@mui/icons-material';
import { InputAdornment, IconButton, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useForm } from 'react-hook-form';
import { FormValues, ResetPasswordFormProps } from './reset-password';
import { getEmailFromToken } from './utils';
import { getSx } from '../sx';
import { RESET_PASSWORD } from '../../../store/user/user.thunk';
import { useAppDispatch, useAppSelector } from '../../../utils/hook/UseReduxHook';
import CustomTypography from '../../UI/CustomComponents/CustomTypography';
import CustomControlledTextField from '../../UI/CustomComponents/CustomControlledTextField';
import CustomButton from '../../UI/CustomComponents/CustomButton';
import { resetPasswordSchema } from '../../../utils/form-validation-schema/user/reset-password-schema';

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  handleModalClose,
}) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const sx = getSx(theme);
  const currentUser = useAppSelector((state) => state.user.current);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userEmail, setUserEmail] = useState<string>(); 

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(resetPasswordSchema),
  });
  
  // Distinction (user not connected & lost password) and (user connected & changing his password)
  useEffect(() => {
    if (currentUser?.email) {
      setUserEmail(currentUser.email);
    }
    if (!currentUser?.email) {
      const userEmailFromUrl = getEmailFromToken(location);
      if (userEmailFromUrl) setUserEmail(userEmailFromUrl);
    }
  }, []);

  const onSubmit = async (data: FormValues) => {
    if (!userEmail) {
      return;
    }
    await dispatch(RESET_PASSWORD({ ...data, email: userEmail, userCode: currentUser?.code }));

    if (currentUser?.email && handleModalClose) {
      handleModalClose();
    }

    if (!currentUser?.email) {
      navigate('/login');
    }
  };

  return (
    <Box sx={{ padding: '5rem' }}>      
      {' '}
      <Box sx={sx.boxTitle}>
        <CustomTypography title="Ré-initialiser" variant="h1" />
        <CustomTypography
          title="votre mot de passe"
          variant="h1"
          sx={{ fontWeight: 'normal' }}
        />
      </Box>
      <form noValidate onSubmit={handleSubmit(onSubmit)} id="login-admin">
        <Box sx={sx.boxTypographie}>
          <CustomControlledTextField
            control={control}
            variant="outlined"
            required
            name="password"
            label="Entrer Votre nouveau mot de passe"
            type={showPassword ? 'text' : 'password'}
            error={!!errors.password}
            errorMessage={errors.password ? errors.password.message : ''}
            size="medium"
            noWrapper
            sx={sx.typoPassword}
            inputProps={{
              sx: sx.typoPasswordInputProps,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="afficher le mot de passe"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: sx.typoPasswordInputLabelsProps,
            }}
          />

          <CustomControlledTextField
            control={control}
            variant="outlined"
            required
            name="confirmPassword"
            label="Confirmer votre nouveau mot de passe"
            autocomplete="confirmPassword"
            type={showConfirmPassword ? 'text' : 'password'}
            id="confirmPassword"
            error={!!errors.confirmPassword}
            errorMessage={
              errors.confirmPassword ? errors.confirmPassword.message : ''
            }
            size="medium"
            noWrapper
            sx={sx.typoPassword}
            inputProps={{
              sx: sx.typoPasswordInputProps,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="afficher le mot de passe"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    onMouseDown={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: sx.typoPasswordInputLabelsProps,
            }}
          />

          <CustomButton
            title="Ré-initialiser le mot de passe"
            type="submit"
            fullWidth
            variant="contained"
            bigButton
            endIcon={<ChevronRight />}
            sx={sx.button}
            typographySx={sx.buttonTypo}
          />
        </Box>
      </form>
    </Box>
  );
};

export default ResetPasswordForm;
