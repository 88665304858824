import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton, Tooltip, Box } from '@mui/material';
import { CustomDrawerProps } from './custom-drawer';

const CustomDrawer: React.FC<CustomDrawerProps> = ({
  menuList, isExpanded, setIsExpanded, drawerWidth, collapsedDrawerWidth,
}) => {
  const theme = useTheme();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isExpanded ? drawerWidth : collapsedDrawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isExpanded ? drawerWidth : collapsedDrawerWidth,
          boxSizing: 'border-box',
          backgroundColor: theme.palette.secondary.light,
          transition: 'width 0.7s ease-out',
        },
      }}
      PaperProps={{
        style: {
          marginTop: '64px',
        },
      }}
    >
      <List
        sx={{
          backgroundColor: theme.palette.secondary.light,
        }}
      >
        {menuList.map((menuItem) => (
          <Tooltip key={menuItem.label} title={menuItem.label} placement="right" arrow>
            <ListItem button component={RouterLink} to={menuItem.path}>
              {menuItem.icon && (
                <ListItemIcon
                  sx={{
                    transition: 'transform 0.3s ease-out',
                    '&:hover': {
                      transform: 'scale(1.7)',
                    },
                  }}
                >
                  <menuItem.icon sx={{ fontSize: '2.5rem', color: theme.palette.secondary.main }} />
                </ListItemIcon>
              )}
              {isExpanded
              && <ListItemText primary={menuItem.label} primaryTypographyProps={{ sx: { fontWeight: '600' } }} />}
            </ListItem>
          </Tooltip>
        ))}
      </List>
      <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
        <IconButton onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Box>
    </Drawer>
  );
};

export default CustomDrawer;
