import { createAsyncThunk } from '@reduxjs/toolkit';
import { UiSlice } from '../ui/ui.slice';
import type {
  ChangeCurrentOrganisationDto, CreateAdminUserDto, ForgotPasswordDto, LoginUserDTO, 
  ResetPasswordDto, UpdateAdminUserDto,
} from './user';
import axiosApiInstance from '../../config/axios';
import { deleteToken, saveToken } from '../../utils/local-storage';
import { ErrorApiModel } from '../../config/error-api.model';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI || '/api';

export const LOG_USER = createAsyncThunk(
  'user/LOG_USER',
  async (payload: LoginUserDTO, thunkApi) => {
    try {
      const { code, password } = payload;
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(
        `${url}/auth/login`,
        { code, password },
      );
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Connecté',
          color: 'success',
          timeout: 3000,
        }),
      );
      deleteToken();
      saveToken(result.data.accessToken);

      return {
        ...result.data.user,
        token: result.data.accessToken,
      };
    } catch (err: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Utilisateur inconnu',
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

export const CHECK_LOG_USER = createAsyncThunk(
  'user/CHECK_LOG_USER',
  async (token: string, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post<any>(`${url}/auth/check-token`, null);
      thunkApi.dispatch(displayLoader(false));
      return {
        ...result.data,
      };
    } catch (err: any) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Veuillez vous connecter',
          color: 'error',
          timeout: 3000,
        }),
      );
      throw err;
    }
  },
);

export const FETCH_ADMIN_USERS = createAsyncThunk(
  'user/FETCH_ADMIN_USERS',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(
        `${url}/users/admin`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const CREATE_ADMIN_USER = createAsyncThunk(
  'user/CREATE_ADMIN_USER',
  async (data: CreateAdminUserDto, thunkApi) => {
    try {
      const result = await axiosApiInstance.post(`${url}/users/admin`, {
        ...data,
      });
      thunkApi.dispatch(
        displayAlert({
          message:
            'Utilisateur créé avec succès - Le mot de passe a été transmis par Email.',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 5000,
        }),
      );
      throw error;
    }
  },
);

export const CHANGE_CURRENT_ORGANISATION = createAsyncThunk(
  'user/CHANGE_CURRENT_ORGANISATION',
  async (data: ChangeCurrentOrganisationDto, thunkApi) => {
    try {
      const result = await axiosApiInstance.patch(`${url}/users/admin/current-organisation`, {
        ...data,
      });
      thunkApi.dispatch(
        displayAlert({
          message: 'Changement d\'Organisation effectué',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 5000,
        }),
      );
      throw error;
    }
  },
);

export const UPDATE_ADMIN_USER = createAsyncThunk(
  'user/UPDATE_ADMIN_USER',
  async (data: UpdateAdminUserDto, thunkApi) => {
    try {
      const result = await axiosApiInstance.patch(`${url}/users/admin`, {
        ...data,
      });
      thunkApi.dispatch(
        displayAlert({
          message: 'Utilisateur modifié avec succès',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 5000,
        }),
      );
      throw error;
    }
  },
);

export const DELETE_USER = createAsyncThunk(
  'user/DELETE_USER',
  async (userCode: string, thunkApi) => {
    try {
      const result = await axiosApiInstance.delete(`${url}/users/${userCode}`);
      thunkApi.dispatch(
        displayAlert({
          message: 'Utilisateur supprimé avec succès',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 5000,
        }),
      );
      throw error;
    }
  },
);

export const FORGOT_PASSWORD = createAsyncThunk(
  'user/FORGOT_PASSWORD',
  async (payload: ForgotPasswordDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(
        `${url}/users/forgot-password`,
        {
          ...payload,
        },
      );
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Email envoyé avec succès',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const RESET_PASSWORD = createAsyncThunk(
  'user/RESET_PASSWORD',
  async (payload: ResetPasswordDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/users/reset-password`, {
        ...payload,
      });
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message:
            'Mot de passe ré-initialisé avec succès',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);
